import React, { useContext, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  LOGIN,
  PRODUCTS,
  DASHBOARD,
  ORDERS,
  USERS,
  INVENTORY,
  EARLY_ACCESS
} from 'settings/constants';
import AuthProvider, { AuthContext } from 'context/auth';
import { InLineLoader } from 'components/InlineLoader/InlineLoader';
const Products = lazy(() => import('containers/Products/Products'));
const AdminLayout = lazy(() => import('containers/Layout/Layout'));
const Dashboard = lazy(() => import('containers/Dashboard/Dashboard'));
const Orders = lazy(() => import('containers/Orders/Orders'));
const Login = lazy(() => import('containers/Login/Login'));
const Users = lazy(() => import('containers/Users/Users'));
const Inventory = lazy(() => import('containers/Inventory/Inventory'));
const EarlyAccess = lazy(() => import('containers/EarlyAccess/EarlyAccess'));
const NotFound = lazy(() => import('containers/NotFound/NotFound'));

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Orders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INVENTORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Inventory />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={USERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Users />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={EARLY_ACCESS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <EarlyAccess />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
