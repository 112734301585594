// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const PRODUCTS = '/products';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';
export const USERS = '/users';
export const INVENTORY = '/inventory';
export const EARLY_ACCESS = '/early-access';
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '€';
