import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import Cookies from "js-cookie";
import { BaseProvider } from 'baseui';
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { ApolloProvider, ApolloClient, InMemoryCache, split, HttpLink } from '@apollo/client';
import { hasSubscription } from "@jumpn/utils-graphql";
import { Socket as PhoenixSocket } from "phoenix";
import * as AbsintheSocket from "@absinthe/socket";
import { theme } from './theme';
import Routes from './routes';
import { auth } from './services/firebase'

import * as serviceWorker from './serviceWorker';
import './theme/global.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Font } from '@react-pdf/renderer';
import { setContext } from 'apollo-link-context';

Font.register({
  family: 'Arial',
  fonts: [
  { src: 'https://perfeqt.s3.us-east-2.amazonaws.com/arial.ttf' },
  { src: 'https://perfeqt.s3.us-east-2.amazonaws.com/arialbd.ttf', fontWeight: 600 }
  ]
});

const authLink = setContext(async (_, { headers }) => {
  // Get the authentication token from Firebase if it exists
  const token = await auth.currentUser?.getIdToken();
  console.log('Firebase token: ', token);
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

// Create an HTTP link to the Absinthe server.
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL
});

// Use setContext to create a chainable link object that sets
// the token cookie to the Authorization header.

// Chain the HTTP link and the authorization link.

// Create a standard Phoenix websocket connection. If you need
// to provide additional params, like an authentication token,
// you can configure them in the `params` option.
const phoenixSocket = new PhoenixSocket(`ws://localhost:4000/socket/websocket?vsn=2.0.0`, {
  params: () => {
    if (Cookies.get("token")) {
      return { token: Cookies.get("token") };
    } else {
      return {};
    }
  }
});

// Wrap the Phoenix socket in an AbsintheSocket.
const absintheSocket = AbsintheSocket.create(phoenixSocket);

// Create an Apollo link from the AbsintheSocket instance.
const websocketLink = createAbsintheSocketLink(absintheSocket);

// If the query contains a subscription, send it through the
// websocket link. Otherwise, send it through the HTTP link.
const link = split(
  operation => hasSubscription(operation.query),
  // TODO: Auth for websocket
  websocketLink,
  // as any due to: https://github.com/apollographql/apollo-link/issues/538
  authLink.concat(httpLink as any) as any,
);

// Apollo also requires you to provide a cache implementation
// for caching query results. The InMemoryCache is suitable
// for most use cases.
const cache = new InMemoryCache();

// Create the client.
const client = new ApolloClient({
  link,
  cache
});

function App() {
  const engine = new Styletron();

  return (
    <ApolloProvider client={client as any}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
