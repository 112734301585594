import React, { useEffect } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { auth, logOut } from '../services/firebase'

type AuthProps = {
  isAuthenticated: boolean;
  setUser: (any) => void;
  authenticate: Function;
  signout: Function;
  user: any;
};

const GET_USER_BY_UID = gql`
  query getUserByUid($uid: String!) {
    getUserByUid(uid: $uid) {
      id
      firstName
      lastName
      email
      role
    }
  }
`;

export const AuthContext = React.createContext({} as AuthProps);

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(false);
  const [user, setUser] = React.useState(null);
  
  const [getUser, { called, loading, data }] = useLazyQuery(GET_USER_BY_UID, {
    onCompleted: (user) => {
      setUser(user.getUserByUid);
      makeAuthenticated(true)
    },
    onError: (e) => console.log("error logging", e)
  });

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
       getUser({ variables: { uid: user.uid } });
      }
    })
  },[])

  function authenticate({ email, password }, cb) {
    makeAuthenticated(true);
    setTimeout(cb, 100); // fake async
  }
  function signout(cb) {
    logOut();
    makeAuthenticated(false);
    setTimeout(cb, 100);
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
        setUser,
        user
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
